import React from "react";

function StatusGreenIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.2 23.5">
      <path d="M11.6 23.5c6.4 0 11.6-5.3 11.6-11.7C23.2 5.3 18 0 11.6 0 5.2 0 0 5.3 0 11.7c0 6.5 5.2 11.8 11.6 11.8zM6.5 10l3.2 3.3L16 6.9l1.8 1.8-8 8.3-5-5.1L6.5 10z"></path>
    </svg>
  );
}

export default StatusGreenIcon;
