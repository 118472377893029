import React from "react";

function RedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.5 23.5">
      <path d="M11.7 0C5.3 0 0 5.3 0 11.7c0 6.5 5.3 11.7 11.7 11.7 6.5 0 11.7-5.3 11.7-11.7C23.5 5.3 18.2 0 11.7 0zm5.6 15.5l-1.8 1.8-3.7-3.7L8 17.3l-1.8-1.8 3.7-3.7L6.2 8 8 6.2l3.7 3.7 3.7-3.7L17.3 8l-3.7 3.7 3.7 3.8z"></path>
    </svg>
  );
}

export default RedIcon;
