import React from "react";

function TswToolBoxLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 555 85">
      <g id="tsw-logo-lockup">
        <g id="tsw-icon">
          <path id="Vector" d="M33.7387 18.6511C30.8066 15.6658 26.4847 14.544 22.5018 15.6829C22.0103 15.821 21.6374 16.2007 21.5019 16.7011C21.3832 17.2015 21.5187 17.7193 21.8747 18.0816L26.7559 23.0689L25.5864 26.8654L21.8408 28.0561L16.9765 23.0861C16.6207 22.7237 16.0953 22.5856 15.6037 22.7237C15.1292 22.8445 14.7393 23.2241 14.6038 23.7074C13.4682 27.7799 14.5868 32.1632 17.5189 35.1486C19.2307 36.8915 21.4171 38.0131 23.739 38.3928C24.9424 38.6172 26.2135 38.6172 27.4508 38.3928C27.6373 38.3756 27.8067 38.341 27.9931 38.3066L28.0778 38.3928H45.7722L36.8403 29.3158C37.6369 25.4676 36.4844 21.4467 33.7387 18.6511Z" fill="black" />
          <path id="Vector_2" d="M66.297 19.6521C65.7207 19.0654 64.8225 18.9791 64.1615 19.4451L55.3991 25.4849C55.1109 25.692 54.8736 25.9853 54.7551 26.3305L53.3313 30.4203L48.0264 35.8217L49.806 37.6164C50.0433 37.858 50.2467 38.1168 50.4162 38.3929H60.0938L60.6362 37.8407L64.653 36.4084C64.992 36.2876 65.2801 36.046 65.4834 35.7526L71.4154 26.8309C71.8561 26.1579 71.7714 25.226 71.1951 24.6565L66.297 19.6521Z" fill="black" />
          <path id="Vector_3" d="M10.248 84.779H75.6187C80.9574 84.779 85.3132 80.3613 85.3132 74.9254V43.5699C85.3132 42.6208 84.5505 41.8442 83.6184 41.8442H78.3982V1.94668C78.3982 0.997582 77.6356 0.221008 76.7033 0.221008H9.16326C8.2311 0.221008 7.4684 0.997582 7.4684 1.94668V41.8442H2.26517C1.31605 41.8442 0.570312 42.6208 0.570312 43.5699V74.9254C0.570312 80.3613 4.90914 84.779 10.248 84.779ZM60.8225 66.8665C60.8225 67.8329 60.0599 68.5922 59.1276 68.5922H26.7389C25.8068 68.5922 25.0441 67.8329 25.0441 66.8665V59.3253C25.0441 58.3762 25.8068 57.5997 26.7389 57.5997C27.688 57.5997 28.4338 58.3762 28.4338 59.3253V65.1408H57.4328V59.3253C57.4328 58.3762 58.1785 57.5997 59.1276 57.5997C60.0599 57.5997 60.8225 58.3762 60.8225 59.3253V66.8665ZM10.8581 3.67236H75.0085V41.8442H10.8581V3.67236Z" fill="black" />
        </g>
        <g id="tsw-typography">
          <path d="M118.751 28.2623H136.205V77.0135H144.631V28.2623H162.112V20.9852H118.751V28.2623Z" fill="black" />
          <path d="M201.097 35.7036H209.25C209.004 26.7303 201.07 20.2192 189.689 20.2192C178.445 20.2192 169.828 26.6482 169.828 36.3054C169.828 44.1024 175.409 48.6711 184.409 51.1059L191.03 52.9115C197.021 54.4982 201.644 56.468 201.644 61.4471C201.644 66.9186 196.419 70.5298 189.224 70.5298C182.713 70.5298 177.296 67.6299 176.804 61.5291H168.323C168.87 71.6788 176.722 77.9437 189.279 77.9437C202.438 77.9437 210.016 71.0222 210.016 61.5291C210.016 51.4342 201.015 47.5221 193.902 45.7712L188.431 44.3486C184.054 43.2269 178.226 41.1751 178.254 35.813C178.254 31.0528 182.604 27.5237 189.498 27.5237C195.927 27.5237 200.495 30.533 201.097 35.7036Z" fill="black" />
          <path d="M229.933 77.0135H238.468L250.259 35.047H250.697L262.488 77.0135H270.996L286.563 20.9852H277.644L266.756 64.4017H266.236L254.883 20.9852H246.073L234.72 64.3743H234.2L223.285 20.9852H214.393L229.933 77.0135Z" fill="black" />
          <path d="M291.337 24.1587H309.256V77.0135H312.676V24.1587H330.595V20.9852H291.337V24.1587Z" fill="black" />
          <path d="M350.252 77.889C360.648 77.889 368.198 68.7516 368.198 56.1397C368.198 43.5279 360.62 34.3631 350.252 34.3631C339.856 34.3631 332.305 43.5005 332.305 56.1397C332.305 68.7516 339.856 77.889 350.252 77.889ZM350.252 74.7976C341.415 74.7976 335.615 66.563 335.615 56.1397C335.615 45.7712 341.47 37.4818 350.252 37.4818C359.061 37.4818 364.888 45.7438 364.888 56.1397C364.888 66.563 359.088 74.7976 350.252 74.7976Z" fill="black" />
          <path d="M394.161 77.889C404.557 77.889 412.108 68.7516 412.108 56.1397C412.108 43.5279 404.53 34.3631 394.161 34.3631C383.765 34.3631 376.214 43.5005 376.214 56.1397C376.214 68.7516 383.765 77.889 394.161 77.889ZM394.161 74.7976C385.324 74.7976 379.525 66.563 379.525 56.1397C379.525 45.7712 385.379 37.4818 394.161 37.4818C402.97 37.4818 408.797 45.7438 408.797 56.1397C408.797 66.563 402.997 74.7976 394.161 74.7976Z" fill="black" />
          <path d="M425.787 20.9852H422.449V77.0135H425.787V20.9852Z" fill="black" />
          <path d="M439.623 77.0135H442.879V67.8488H443.262C445.259 73.2656 449.636 77.889 456.831 77.889C466.953 77.889 473.574 68.8336 473.574 56.1123C473.574 43.4458 466.899 34.3631 456.804 34.3631C449.609 34.3631 445.204 38.9591 443.262 44.3759H442.933V20.9852H439.623V77.0135ZM442.906 56.085C442.906 45.3608 448.022 37.4818 456.557 37.4818C465.202 37.4818 470.264 45.525 470.264 56.085C470.264 66.645 465.202 74.7976 456.557 74.7976C448.022 74.7976 442.906 66.8365 442.906 56.085Z" fill="black" />
          <path d="M499.557 77.889C509.953 77.889 517.504 68.7516 517.504 56.1397C517.504 43.5279 509.925 34.3631 499.557 34.3631C489.161 34.3631 481.61 43.5005 481.61 56.1397C481.61 68.7516 489.161 77.889 499.557 77.889ZM499.557 74.7976C490.72 74.7976 484.921 66.563 484.921 56.1397C484.921 45.7712 490.775 37.4818 499.557 37.4818C508.366 37.4818 514.193 45.7438 514.193 56.1397C514.193 66.563 508.393 74.7976 499.557 74.7976Z" fill="black" />
          <path d="M527.373 34.9923H523.488L536.894 56.0029L523.488 77.0135H527.373L538.973 58.6292L550.572 77.0135H554.43L540.97 56.0029L554.43 34.9923H550.572L538.973 53.6775L527.373 34.9923Z" fill="black" />
        </g>
      </g>
    </svg>
  );
}

export default TswToolBoxLogo;
