import * as React from "react"

import StatusGreen from "../assets/icons/StatusGreen"
import StatusYellow from "../assets/icons/StatusYellow"
import StatusRed from "../assets/icons/StatusRed"

const StatusItem = ({statusItem, statusMessage}) => {
  return(
   <div className="status-item">
      {statusItem === "Operational" && 
        <div className="green-icon"><StatusGreen/></div>
      }
       {statusItem === "Some_Problems" && 
        <div className="orange-icon"><StatusYellow/></div>
      }
       {statusItem === "Down" && 
        <div className="red-icon"><StatusRed /></div>
      }
      <div className="status-label">
       {statusMessage} 
      </div>
   </div>
  )
}


export default StatusItem
