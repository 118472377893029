import React from "react";

function YellowIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.6 23.6">
      <path d="M11.8 0C5.3 0 0 5.3 0 11.8c0 6.5 5.3 11.8 11.8 11.8 6.5 0 11.8-5.3 11.8-11.8C23.6 5.3 18.3 0 11.8 0zm1.4 17.7h-2.9v-2.9h2.9v2.9zm0-4.5h-2.9V5.9h2.9v7.3z"></path>
    </svg>
  );
}

export default YellowIcon;